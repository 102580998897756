import React, { useContext } from "react";
import { FaSignOutAlt, FaCalendarAlt } from "react-icons/fa";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { SlSupport } from "react-icons/sl";

import { AuthContext } from "../../context/Authorization";
import { UserContext } from "../../context/User";
import getUserInitials from "../../utils/getUserInitials";
import { useNavigate } from "react-router-dom";

const ProfileMenu = ({ isOpen, toggleMenu }) => {
  const { user } = useContext(UserContext);
  const { useAuth, logoutQlik } = useContext(AuthContext);
  const { userHasPermission } = useAuth();

  const navigate = useNavigate();

  const getInitials = () => {
    return user?.profile?.name ? (
      getUserInitials(user.profile.name)
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="#808080"
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        />
      </svg>
    );
  };

  const adminMenu = () => {
    if (!userHasPermission(["ROUTE_ADMIN_VIEW"])) return null;

    return (
      <>
        <div className="border-[0.5px] border-solid border-grey-hover"></div>
        <button
          onClick={() => {
            navigate("/admin");
          }}
          className="w-full flex items-center px-4 py-2 text-base text-main hover:bg-grey-hover"
        >
          <MdOutlineDisplaySettings className="mr-3 h-5 w-5 text-red-800" />
          Admin Panel
        </button>
      </>
    );
  };

  return (
    <div className="relative ml-4">
      <button
        onClick={toggleMenu}
        className="flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
        data-testid="profile-menu-test"
      >
        <div className="flex items-center justify-center">
          <div className="flex justify-center items-center w-8 h-8  bg-grey-hover hover:bg-grey rounded-full">
            <span className="text-base text-section-title-color">{getInitials()}</span>
          </div>
        </div>
      </button>
      {isOpen && (
        <div
          style={{ zIndex: 9999 }}
          className="absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-font-color ring-1 ring-black ring-opacity-5"
          data-testid="open-profile-menu-test"
        >
          <div className="py-1">
            <div className="grid grid-cols-4 px-4 py-2">
              <div className="flex items-center justify-center">
                <div className="flex justify-center items-center w-20 h-20  bg-grey-hover rounded-full ">
                  <span className="text-3xl text-section-title-color font-bold">{getInitials()}</span>
                </div>
              </div>
              <div className="col-span-3 px-4 py-3 text-sm text-gray-900 dark:text-red">
                <div className="text-xl font-bold text-subsection-title-color">{user.profile.name}</div>
                <div className="text-base text-grey">{user.profile.email}</div>
                <div className="text-sm pt-4 text-grey">Enolytics v2.0</div>
              </div>
            </div>
            <div className="border-[0.5px] border-solid border-grey-hover"></div>
            <div className="space-y-1 py-2">
              <button
                onClick={() => {
                  window.open("https://www.enolytics.com/schedule-a-workshop", "_blank");
                }}
                className="w-full flex items-center px-4 py-2 text-base text-blue hover:bg-grey-hover"
              >
                <FaCalendarAlt className="mr-3 h-5 w-5 text-blue" />
                Schedule Workshop
              </button>
              <button
                onClick={() => {
                  window.open("https://help.enolytics.com", "_blank");
                }}
                className="w-full flex items-center px-4 py-2 text-base text-blue hover:bg-grey-hover"
              >
                <SlSupport className="mr-3 h-5 w-5 text-blue" />
                Support Portal
              </button>
              <button
                data-testid="logout-button"
                onClick={() => {
                  logoutQlik();
                  user.auth.auth.signOut();
                }}
                className="w-full flex items-center px-4 py-2 text-base text-blue hover:bg-grey-hover"
              >
                <FaSignOutAlt className="mr-3 h-5 w-5 text-blue" />
                Sign out
              </button>

              {adminMenu()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
