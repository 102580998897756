const FirebaseConfig = {
  apiKey: "AIzaSyDA917_-q-0NYiR-grHvHXEniUy7-I3Jwc",
  authDomain: "genesis-210918.firebaseapp.com",
  databaseURL: "https://genesis-210918.firebaseio.com",
  projectId: "genesis-210918",
  storageBucket: "genesis-210918.appspot.com",
  messagingSenderId: "534810925392",
  appId: "1:534810925392:web:95b80d949d32b86ab63455",
  measurementId: "G-QGHE7GMJPP",
};

const QlikConfig = {
  prefix: "myeno",
  host: "dashboards.enolytics.com",
  port: 443,
  isSecure: true,
};

const QlikApps = {
  haasbros: "0051546e-a490-4629-9c07-33a215d46d1d",
  haasbrosLegacy: "0051546e-a490-4629-9c07-33a215d46d1d",
  depletion: "8e264793-ca70-483b-843f-c593c1f740ca",
  docNames: {
    dtc: "DTC",
    depletion: "Depletion",
    haasbros: "Haas",
    depl: "DEPL",
  },
};

const AstratoConfig = {
  baseURL: "https://app.astrato.io/",
  appName: {
    dtc: "DTC",
    "v2/dtc": "DTC",
    depl: "DEPL",
    "v2/depl": "DEPL",
    haasbros: "HAAS",
  },
};

export { FirebaseConfig, QlikConfig, QlikApps, AstratoConfig };
