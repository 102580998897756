import React from "react";

const NoRouteV2 = () => {
  return (
    <div className="flex mt-24 justify-center h-screen">
      <div className="text-center text-main">
        <h1 className="text-2xl font-bold mb-4">No access</h1>
        <p className="text-subsection-title-color">
          Sorry, but you do not have access to this resource.
          <br />
          This feature may not be part of your plan.
        </p>
        <p className="mt-4">Please contact support for assistance.</p>
        <button
          onClick={() => {
            window.open("https://help.enolytics.com", "_blank");
          }}
          className="mt-8 inline-block bg-main text-white text-xl font-semibold py-3 px-6 rounded-lg hover:bg-main-hover transition-colors duration-300"
        >
          Contact Support
        </button>
      </div>
    </div>
  );
};

export default NoRouteV2;
